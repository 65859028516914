import React, { useEffect } from 'react';
import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getvillages } from '../../store/village';

const VillagesList = () => {
  const dispatch = useDispatch();
  const { villages } = useSelector(state => ({
    villages: state.villages.villages
  }));

  useEffect(() => {
    dispatch(getvillages())
  }, [dispatch]);
  
  dispatch(getvillages)
  const classes = useStyles();
  return (
    <>
      <h1>
        Villages
      </h1>
      <Link to="/villages/create">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<AddIcon />}
        >
          Create new village
        </Button>
      </Link>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ 'fontWeight': 'bold' }} size="small">ID</TableCell>
              <TableCell style={{ 'fontWeight': 'bold' }} align="left">Village&nbsp;name</TableCell>
              <TableCell style={{ 'fontWeight': 'bold' }} align="left">Population</TableCell>
              <TableCell style={{ 'fontWeight': 'bold' }} align="left">Registered&nbsp;users</TableCell>
              <TableCell style={{ 'fontWeight': 'bold' }} align="left">Posts</TableCell>
              <TableCell style={{ 'fontWeight': 'bold' }} align="left">Groups</TableCell>
              <TableCell style={{ 'fontWeight': 'bold' }} align="left">Invite&nbsp;code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {villages?.map((village) => (
              <TableRow key={village.name+village.id}>
                  <TableCell component="th" scope="village" size="small">
                    {village.id}
                  </TableCell>
                  <TableCell align="left">
                    <Link to={`villages/${village.id}`}>
                      {village.name}
                    </Link>
                    </TableCell>
                  <TableCell align="left">{village.population}</TableCell>
                  <TableCell align="left">{village.userCount}</TableCell>
                  <TableCell align="left">{village.postCount}</TableCell>
                  <TableCell align="left">{village.groupCount}</TableCell>
                  <TableCell align="left">{village.invite_code}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles({
  button: {
    float: 'right',
    marginBottom: '.5rem'
  },
  table: {
    minWidth: 650,
  },
});

export default VillagesList