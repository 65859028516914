import Auth from 'pages/auth';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import routes from './routes';

const Routes = () => {
  return (
    <Switch>
      <Route
        path={'/login'}
        exact={true}
        component={Auth}
      />
      {routes.map(({ Component, ...rest }, idx) => (
          <ProtectedRoute key={idx} {...rest}>
            <Component />
          </ProtectedRoute>
        ))}
    </Switch>
  );
};

export default Routes;
