import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ConnectioAccess from './ConnectionAccess';

const VillageList = ({ villages }) => {
  return (
    <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ 'fontWeight': 'bold' }} size="small" >ID</TableCell>
              <TableCell style={{ 'fontWeight': 'bold' }} align="left">Village&nbsp;name</TableCell>
              <TableCell style={{ 'fontWeight': 'bold' }} align="left">Population</TableCell>
              <TableCell style={{ 'fontWeight': 'bold' }} align="left">All posts access</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {villages?.map((village, idx) => (
              <TableRow key={idx}>
                <TableCell component="th" scope="row" size="small">
                  {village.id}
                </TableCell>
                <TableCell align="left">{village.name}</TableCell>
                <TableCell align="left">{village.population}</TableCell>
                <TableCell align="left">
                  <ConnectioAccess village={village} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default VillageList;
