import React, { useState } from 'react';
import { Button, makeStyles, Paper, TextField, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { createVillage, getvillages } from 'store/village';

const data = {
  name: '',
  subdomain_name: '',
  url: 'https://',
  population: '',
  invite_code: ''
}
const CreateVillage = () => {
  const [village, setVillage] = useState(data);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const closePage = () => {
    history.push('/villages');
  };
  const handleNameChange = (e) => {
    const name = e.currentTarget.name;
    let value = e.currentTarget.value;
    if (name === 'name') {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    setVillage((state) => ({ ...state, [name]: value }))
  };
  const submitVillage = () => {
    dispatch(createVillage(village));
    dispatch(getvillages());
    history.push('/villages');
  };
  return (
    <Paper elevation={3} className={classes.root}>
      <IconButton style={{ 'float': 'right' }} onClick={closePage} aria-label="delete">
        <CloseIcon />
      </IconButton>
      <h2>Create new village</h2>
      <div className={classes.formContainer}>
        <TextField
          fullWidth
          className={classes.input}
          name="name"
          label="Village name"
          variant="outlined"
          size="small"
          value={village.name}
          onChange={handleNameChange}
        />
        <TextField
          fullWidth
          className={classes.input}
          name="subdomain_name"
          label="Subdomain name"
          value={village.subdomain_name}
          variant="outlined"
          size="small"
          onChange={handleNameChange}
        />
        <TextField
          fullWidth
          className={classes.input}
          name="url"
          label="URL address"
          variant="outlined"
          value={village.url}
          size="small"
          onChange={handleNameChange}
        />
        <TextField
          fullWidth
          className={classes.input}
          name="population"
          label="Village population"
          value={village.population}
          variant="outlined"
          size="small"
          onChange={handleNameChange}
        />
        <TextField
          fullWidth
          className={classes.input}
          name="invite_code"
          label="Invite code"
          variant="outlined"
          value={village.invite_code}
          size="small"
          onChange={handleNameChange}
        />
      </div>
    <Button
      className={classes.btn}
      variant="contained"
      color="primary"
      onClick={submitVillage}
    >
      Create village
    </Button>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
      padding: '20px',
  },
  formContainer: {
    width: '60%',
    margin: '0 auto'
  },
  input: {
    margin: '1rem 0'
  },
  btn: {
    margin: '2rem auto',
    display: 'flex'
  }
}));

export default CreateVillage;
