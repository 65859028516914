import React, { useEffect, useState } from 'react';
import { Button, makeStyles, Paper, TextField, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SelectVillages from './SelectVillages';
import { Chip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createConnection, getConnections } from 'store/connection';
import { useHistory } from 'react-router-dom';
import { getUnconnectedVillages, selectUnconnectedVillages } from 'store/connection';


const CreateConnection = () => {
  const villages = useSelector(selectUnconnectedVillages);
  const classes = useStyles();
  const history = useHistory();
  const [chosenVillages, setVillages] = useState([]);
  const [connectionName, setConnectionName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!villages) {
      dispatch(getUnconnectedVillages());
    }
  }, [dispatch, villages]);

  const handleNameChange = (e) => {
    const value = e.currentTarget.value;
    setConnectionName(value);
  };

  const submitConnection = () => {
    const data = {
      name: connectionName,
      villages: chosenVillages.map(v => v.id)
    };
    dispatch(createConnection(data));
    dispatch(getConnections());
    history.push('/connections');
  };

  const closePage = () => {
    history.push('/connections');
  };

  const handleDelete = (village) => {
    setVillages((villages) => villages.filter(v => v.id !== village.id));
  };

  return (
    <Paper elevation={3} className={classes.root}>
       <IconButton style={{ 'float': 'right' }} onClick={closePage} aria-label="delete">
        <CloseIcon />
      </IconButton>
      <h2>Create new village connection</h2>
      <TextField
        fullWidth
        id="connection-name"
        name="connection-name"
        label="Connection name"
        variant="outlined"
        onChange={handleNameChange}
      />
      <SelectVillages
        title="Select Villages"
        villages={villages}
        checkedVillages={chosenVillages}
        setVillages={setVillages}
      />
      <ul className={classes.chipList}>
      {chosenVillages.map((village) => (
          <li key={village.name} className={classes.listItem}>
            <Chip
              label={village.name}
              className={classes.chip}
              onDelete={() => handleDelete(village)}
            />
          </li>
        ))}
    </ul>
    <Button
      className={classes.btn}
      variant="contained"
      color="primary"
      onClick={submitConnection}
    >
      Create village connection
    </Button>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
      padding: '20px',
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  chipList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  listItem: {
    height: 'fit-content'
  },
  btn: {
    margin: '2rem auto',
    display: 'flex'
  }
}));

export default CreateConnection;
