import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Button, ButtonGroup, Paper } from '@material-ui/core';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getUserActivity } from 'store/user';

const UsersActivity = () => {
  const buttons = ['day', 'week', 'month']
  const [period, setPeriod] = useState('day');
  let { userActivity } = useSelector((state) => ({
    userActivity: state.users.userActivity
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      period: period === 'day' ? 1 : period === 'week' ? 7 : 30
    };
    dispatch(getUserActivity(params));
  }, [dispatch, period]);
  const itemsNumber = userActivity ? userActivity.length : null;

  if (!userActivity) return null;

  return (
    <StyledUserActivity itemsNumber={itemsNumber}>
      <h3>Users activity</h3>
      <div className="chart-container">
        <ButtonGroup className="buttons-container">
          {buttons.map((button) => (
            <Button
              key={button}
              color={period === button ? 'primary' : 'default'}
              variant={period === button ? 'contained' : 'outlined'}
              onClick={() => setPeriod(button)}
              disableElevation
            >
              {button}
            </Button>
          ))}
        </ButtonGroup>
        <ResponsiveContainer>
          <BarChart layout="vertical" data={userActivity} margin={{ top: 5, right: 10, left: 60, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis type="category" dataKey="name" />
            <XAxis type="number" dataKey={(v)=>parseInt(v.visited)} domain={[0, 'dataMax+10']} />
            <Tooltip />
            <Bar dataKey="visited" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </StyledUserActivity>
  );
};

const StyledUserActivity = styled(Paper)`
  padding: 0;
  margin-top: 30px;
  position: relative;
  h3 {
    margin: 0;
    padding: 10px;
    background: #284eff1c;
    border-radius: 4px 4px 0 0;
  }
  .chart-container {
    margin-top: 50px;
    min-height: 300px;
    height: ${({ itemsNumber }) => (itemsNumber ? `${itemsNumber / 3 * 100}px` : '300px')};
  } 
  .buttons-container {
    position: absolute;
    right: 10px;
    top: 50px;
  }
  .recharts-cartesian-axis-tick-value {
    font-size: 10px;
    font-weight: 600;
  }
`;

export default UsersActivity;
