import React, { useRef } from 'react';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ImagesPreview = ({images, addImages, deleteImage, classes}) => {
    const addImagesButtonRef = useRef();
    
    const onClick =() => {
        addImagesButtonRef.current.click()
    };

    return (
      <div className={classes.imagesContainer}>
        <Button onClick={onClick} className={classes.addImagesButton} variant="contained" color="primary" style={{ width: '200px' }}>
          <label>
            Add images
          </label>
        </Button>
          <input
            className={classes.uploadInput}
            ref={addImagesButtonRef}
            type="file"
            accept="image/*"
            multiple
            onChange={e => addImages(e)}
          />
        <div>
          {images?.length > 0 && images.map((el, i) => {
            return (
              <div key={i} className={classes.imageCard}>
                <IconButton key={i} style={{ 'float': 'right' }} onClick={() => deleteImage(i)} aria-label="delete">
                  <CloseIcon />
                </IconButton>
                <img src={`${el}`} alt={`Advertisement №${i+1}`} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  export default ImagesPreview;
