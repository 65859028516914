export const getFormDataFromObject = (object) => {
    const data = new FormData();
  
    Object.keys(object).forEach((key) => {
      if (object[key]) {
        if (Array.isArray(object[key])) {
          object[key].forEach((el) => data.append(key, el));
          return;
        }
        data.append(key, object[key]);
      }
    });

    return data;
  };
  /**
   * FormData object with empty fields to make possible update fields in data base
   */
  export const createFormData = (object) => {
    const data = new FormData();
  
    Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        object[key].forEach((el) => data.append(key, el));
        return;
      }
      const value = object[key] ? object[key] : '';
      data.append(key, value);
    });
    return data;
  };
  