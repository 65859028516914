import React from 'react';
import { Switch,  makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getConnections, selectError, selectLoading, updateConnectionAccess } from 'store/connection';

const ConnectionAccess = ({ village }) => {
  const classes = useStyles();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const dispatch = useDispatch();

  const handleChangeAccess = () => {
    const data = {
      id: village.id,
      connection_access: !village.connection_access
    }
    dispatch(updateConnectionAccess(data));
    if (!loading && !error) {
      dispatch(getConnections());
    }
  }
  return (
    <div id={village.name} className={classes.wrapper}>
      {village.connection_access ? 'enable' : 'disable'}
      <Switch
        checked={village.connection_access}
        onChange={handleChangeAccess}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default ConnectionAccess;
