import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Routes from '../routes';

const Main = () => {
  const { isSidebarOpen } = useSelector((state) => ({
    isSidebarOpen: state.app.isSidebarOpen,
  }));
  const classes = useStyles({ isSidebarOpen });
  return (
    <div className={classes.root}>
      <Routes />
    </div>
  )
};

const useStyles = makeStyles(theme => ({
  root: {
    // marginLeft: ({ isSidebarOpen }) => (isSidebarOpen ? theme.sidebar.width : 0),
    margin: '50px auto 0',
    maxWidth: '1000px',
    padding: '2rem',
  }
}));

export default Main;