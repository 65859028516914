import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper, Button } from '@material-ui/core';
import logo from 'icons/logo.png';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { login, selectProfile } from 'store/profile';
import { useHistory } from 'react-router-dom';

const initialValues ={
  email: '',
  password: ''
};

const Auth  = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(selectProfile);
  const submitForm = (values) => {
    dispatch(login(values));
  };
  useEffect(() => {
    if (profile) {
      history.push('/');
    }
  }, [dispatch, history, profile])
  return (
    <Paper elevation={3} className={classes.root}>
      <div className={classes.logo}>
        <img className={classes.img} src={logo} alt="logo" />
      </div>
      <h2 className={classes.title}>VILLAGES ADMIN</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
      >
        {({ handleSubmit, handleChange }) => (
          <Form
            className={classes.inputContainer}
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              id="email"
              name="email"
              onChange={handleChange}
              className={classes.input}
              label="Email"
              variant="outlined"
              />
            <TextField
              fullWidth
              id="password"
              name="password"
              onChange={handleChange}
              className={classes.input}
              label="Password"
              type="password"
              variant="outlined"
              autoComplete="off"
              />
            <Button
              className={classes.btnSubmit}
              variant="contained"
              color="primary"
              type="submit"
            >
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
      maxWidth: '600px',
      margin: '0 auto'
  },
  inputContainer: {
    width: '60%',
    margin: '0 auto',
    padding: '1rem'
  },
  input: {
    margin: theme.spacing(1)
  },
  logo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  img: {
    width: '40%',
    height: 'auto'
  },
  title: {
    textAlign: 'center',
    color: 'blue'
  },
  btnSubmit: {
    margin: '2rem auto 4rem',
    display: 'flex'
  }
}));

export default Auth;

