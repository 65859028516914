import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getvillageMetrics } from 'store/village';

const VillageMetrics = () => {
  const params = useParams();
  const { id } = params;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { villageMetrics } = useSelector((state) => ({ villageMetrics: state.villages.villageMetrics }));

  useEffect(() => {
    dispatch(getvillageMetrics(id))
  } ,[dispatch, id]);

  if (!villageMetrics) {
    return null;
  }

  const { userActivity } = villageMetrics;

  return (
    <TableContainer component={Paper}>
      <h3 className={classes.tableHeader}>{villageMetrics.name} metrics</h3>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.textBold} size="small" />
            <TableCell className={classes.textBold} align="center">Users</TableCell>
            <TableCell className={classes.textBold} align="center">Events</TableCell>
            <TableCell className={classes.textBold} align="center">Marketplace</TableCell>
            <TableCell className={classes.textBold} align="center">Groups</TableCell>
            <TableCell className={classes.textBold} align="center">Likes</TableCell>
            <TableCell className={classes.textBold} align="center">Comments</TableCell>
            <TableCell className={classes.textBold} align="center">Posts</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.textBold} component="th" scope="village" size="small">
              Total
            </TableCell>
            <TableCell align="center">{villageMetrics.usersCount}</TableCell>
            <TableCell align="center">{villageMetrics.eventsCount}</TableCell>
            <TableCell align="center">{villageMetrics.marketCount}</TableCell>
            <TableCell align="center">{villageMetrics.groupsCount}</TableCell>
            <TableCell align="center">{villageMetrics.likesCount}</TableCell>
            <TableCell align="center">{villageMetrics.commentsCount}</TableCell>
            <TableCell align="center">{villageMetrics.postsCount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <h3 className={classes.tableHeader}>{villageMetrics.name} users activity</h3>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.textBold} size="small" />
            <TableCell className={classes.textBold} align="center">24h</TableCell>
            <TableCell className={classes.textBold} align="center">72h</TableCell>
            <TableCell className={classes.textBold} align="center">7 days</TableCell>
            <TableCell className={classes.textBold} align="center">31 days</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.textBold} component="th" scope="village" size="small">
              By last session date
            </TableCell>
            <TableCell align="center">{userActivity.day}</TableCell>
            <TableCell align="center">{userActivity.threeDays}</TableCell>
            <TableCell align="center">{userActivity.week}</TableCell>
            <TableCell align="center">{userActivity.month}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles({
  textBold: {
    fontWeight: 'bold'
  },
  tableHeader: {
    background: '#284eff1c',
    marginTop: 0,
    padding: '.5rem'
  },
  tableHeaderUsers: {
    marginTop: '30px'
  },
  table: {
    minWidth: 650,
    marginBottom: '40px'
  },
});

export default VillageMetrics;
