import axios from '.';

export const getVillagesRequest = () => {
  return axios.get('/village');
};

export const getVillageMetricsRequest = (id) => {
  return axios.get(`/village/${id}/metrics`)
};

export const createVillagesRequest = (data) => {
  return axios.post('/village', data);
};

export const downloadDbBackup = (hash) => {
  return axios.get(`/village/backup/${hash}`);
};

export const getDbBackup = () => {
  return axios.get(`/village/backup`);
};
