import React, { useEffect, useState } from 'react';
import { makeStyles, Paper, TextField, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import VillageOptions from './VillageOptions.js'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getvillages } from '../../store/village';


const ChooseVillage = () => {
  const [chosenVillage, setChosenVillage] = useState(null);
  
  const { villages, villagesNames } = useSelector(state => ({
    villagesNames: state.villages.villages && state.villages.villages.map(el => el.name),
    villages: state.villages.villages
  }));

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch(getvillages());
  }, [dispatch]);
  
  dispatch(getvillages)
  

  useEffect(() => {
    dispatch(getvillages());
  }, [dispatch]);

  const closePage = () => {
    history.push('/connections');
  };

  const onVillageChosen = (event) => {
    event.preventDefault();
    const villageName = event.target.innerHTML;
    const chosenVillage = villages.filter(v => v.name === villageName)[0]
    setChosenVillage(chosenVillage);
  }

  return (
    <>
      <Paper elevation={3} className={classes.root}>
        <IconButton style={{ 'float': 'right' }} onClick={closePage} aria-label="delete">
          <CloseIcon />
        </IconButton>
        <h2>Choose village to configure</h2>
        <Autocomplete
          id="grouped-demo"
          options={villagesNames || []}
          autoSelect={true}
          groupBy={(option) => option.firstLetter}
          sx={{ width: 300 }}
          onClose={(e) => onVillageChosen(e)}
          renderInput={(params) => <TextField {...params} label="Village name" />}
        />

      </Paper>
      {chosenVillage &&
        <VillageOptions
          key={chosenVillage.id}
          village={chosenVillage}
        />
      }
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
      padding: '20px',
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  chipList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  listItem: {
    height: 'fit-content'
  },
  btn: {
    margin: '2rem auto',
    display: 'flex'
  }
}));

export default ChooseVillage;
