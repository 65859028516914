import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app';
import villageReducer from './village';
import connectionReducer from './connection';
import profileReducer from './profile';
import usersReducer from './user';
import villageConfigurationsReducer from './villageConfigs';

export const store = configureStore({
  reducer: {
    app: appReducer,
    villages: villageReducer,
    connections: connectionReducer,
    profile: profileReducer,
    users: usersReducer,
    villageConfigurations: villageConfigurationsReducer
  },
});
