import config from '../config';

const prefix = config.serverUrl.replace('/api', '');

const addPrefix = (url) => {
    return `${prefix}${url}`;
};

const deletePrefix = (url) => {
    const newUrl = url.replace(prefix, '')
    return newUrl;
};

export {
    addPrefix,
    deletePrefix
};
