import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Chip, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SelectVillages from './SelectVillages';
import VillageList from './VillageList';
import { useDispatch, useSelector } from 'react-redux';
import {
  addVillageToConnection,
  getUnconnectedVillages,
  selectUnconnectedVillages,
  selectError,
  selectLoading,
  getConnections
} from 'store/connection';

const Connection = ({ connection }) => {
  const classes = useStyles();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const [chosenVillages, setVillages] = useState([]);

  const villages = useSelector(selectUnconnectedVillages);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!villages) {
      dispatch(getUnconnectedVillages());
    }
  }, [dispatch, villages]);

  const handleDelete = (village) => {
    setVillages((villages) => villages.filter(v => v.id !== village.id));
  };

  const addVillagesToConnection = () => {
    const data = {
      connection: connection.id,
      villages: chosenVillages.map(v => v.id)
    };
    dispatch(addVillageToConnection(data));
    if (!loading && !error) {
      dispatch(getConnections());
      setVillages([]);
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <h2>{connection.name}</h2>
        <ul className={classes.chipList}>
        {chosenVillages.map((village) => (
          <li key={village.name} className={classes.listItem}>
            <Chip
              label={village.name}
              className={classes.chip}
              onDelete={() => handleDelete(village)}
            />
          </li>
        ))}
        </ul>
        <SelectVillages
          className={classes.smallBtn}
          title="Add Village"
          variant="contained"
          color="primary"
          icon={<AddIcon />}
          villages={villages}
          checkedVillages={chosenVillages}
          setVillages={setVillages}
        />
        <VillageList villages={connection.villages} />
          {!!chosenVillages.length && (
            <Button
              variant="contained"
              size="small"
              className={classes.updateBtn}
              onClick={addVillagesToConnection}
            >
              Update connection
            </Button>
          )}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    minWidth: 275,
    marginBottom: '1.5rem',
    background: '#b1b3dd33'
  },
  chipList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  listItem: {
    height: 'fit-content'
  },
  chip: {
    margin: theme.spacing(0.5),
    background: '#f67a093d'
  },
  smallBtn: {
    marginBottom: '1rem',
    float: 'right'
  },
  updateBtn: {
    position: 'absolute',
    top: '20px',
    right: '10px',
    background: '#f6a509',
    '&:hover': {
      backgroundColor: '#ff9595c4'
    }
  }
}));

export default Connection;
