import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, CardContent, makeStyles, TextField, Checkbox, Box } from '@material-ui/core';
import { getVillageConfig, createCustomConfig, updateConfigurations } from 'store/villageConfigs';
import { useDispatch, useSelector } from 'react-redux';
import ImagesContainer from './ImagesContainer'
import { deletePrefix } from '../../utils/prefixes'

const PICTURES_LIMIT = 8;

const VillageOptions = ({ village }) => {
  const [villageOptions, setVillageOptions] = useState(null);
  const [isAdvertisementEnabled, setisAdvertIsementEnabled] = useState(false);
  const [isLiveOptionEnabled, setIsLiveOptionEnabled] = useState(true);
  const [advertisementImages, setAdvertisementImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (village) {
      dispatch(getVillageConfig(village.id));
    }
  }, [dispatch, village]);

  const { villageConfigs } = useSelector(state => ({
    villageConfigs: state.villageConfigurations.currentConfiguration,
  }));

  const _villageConfigs = useMemo(() => (
    villageConfigs), [villageConfigs]
  );

  useEffect(() => {
    setVillageOptions(villageConfigs);
    setisAdvertIsementEnabled(villageConfigs?.isAdvertisementEnabled);
    setIsLiveOptionEnabled(villageConfigs?.isLiveOptionEnabled);
    setPreviewImages(villageConfigs?.advertisementImages || [])
  }, [_villageConfigs])

  const onValuesChange = (e, index, field) => {
    const currentOption = villageOptions.customOptions[index];
    let updatedOption = { ...currentOption }
    if (currentOption.type === 'input') {
      e.preventDefault();
      const value = e.target.value;
      updatedOption[field] = value;
    }
    if (currentOption.type === 'checkbox') {
      updatedOption = { ...currentOption, value: !currentOption.value };
    }
    
    const updatedCustomOptions = villageOptions.customOptions.map((el, i) => {
      if (i === index) return updatedOption;
      return el;
    })

    setVillageOptions({
      ...villageOptions,
      customOptions: updatedCustomOptions
    });
  }

  const handleCheckbox = (prevValue, setValue) => {
    setValue(!prevValue);
  }

  const addImages = (e) => {
    e.preventDefault();

    const files = e.target.files;
    try {
      // 'files' is {}
      if (files && files.length > 0) {
        const previews = [];
        for (let el in files) {
          if (files[el]?.type && files[el]?.type.includes('image')) previews.push(URL.createObjectURL(files[el]));
        };
        setAdvertisementImages(Array.from(files));
        setPreviewImages([...previewImages, ...previews].slice(0, PICTURES_LIMIT));
      } else {
        // Error handler
      }
    } catch (err) {
      // Handle error
      console.log(err)
    }
  }

  const saveVillageOptions = async () => {
    try {
      const newConfiguration = {
        village_id: village.id,
        customOptions: villageOptions.customOptions.map(el => JSON.stringify(el)),
        isLiveOptionEnabled,
        isAdvertisementEnabled,
        advertisementImages
      }
      if (villageOptions?.isDefault) {
        await dispatch(createCustomConfig(newConfiguration))
      } else {
        newConfiguration.advertisementImages = [...previewImages.filter(el => !(/blob:/.test(el))).map(url => deletePrefix(url))];
        newConfiguration.newImages = advertisementImages;
        await dispatch(updateConfigurations({ ...newConfiguration, id: villageOptions.id }));
      }
      
      setAdvertisementImages([])
      dispatch(getVillageConfig(village.id))
    } catch (err) {
      console.log(err)
    }
  }

  // TODO: Move this element to separate component
  const renderCustomOption = (customOption, i) => {
    switch (customOption.type) {
      case 'input':
        return (
          <Box className={classes.optionsContainer}>
            <TextField
              key ={`${customOption.name}_card`}
              value={customOption.title}
              label="Card title"
              placeholder={customOption.title}
              className={classes.input}
              type="text"
              onChange={(e) => onValuesChange(e, i, 'title')}
            />
            <TextField
              key ={customOption.name}
              value={customOption.url || ''}
              label="Card url"
              placeholder={`${customOption.title} address`}
              className={classes.input}
              type="text"
              onChange={(e) => onValuesChange(e, i, 'url')}
            />
          </Box>
        )
      case 'checkbox':
        return (
          <div className={classes.checkbox}>
          <p>{customOption.title}</p>
          <Checkbox
            key={customOption.name}
            checked={customOption.value}
            onChange={(e) => onValuesChange(e, i)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        )
      default: return
    };
  };

  const deleteImage = (index) => {
    setAdvertisementImages(advertisementImages.filter((el, i) => i !== index ))
    setPreviewImages(previewImages.filter((el, i) => i !== index ))
  }

  return villageOptions ? (
    <Card className={classes.root}>
      <CardContent className={classes.card}>
        <h2>{village.name}</h2>
        <div className={classes.checkbox}>
          <p>Enable Live</p>
          <Checkbox
            checked={isLiveOptionEnabled}
            onChange={() => handleCheckbox(isLiveOptionEnabled,  setIsLiveOptionEnabled)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        {villageOptions.customOptions && villageOptions.customOptions.map((el, i) => renderCustomOption(el, i))}
        <div className={classes.checkbox}>
          <p>Enable Advertisement</p>
          <Checkbox
            checked={isAdvertisementEnabled}
            onChange={() => handleCheckbox(isAdvertisementEnabled, setisAdvertIsementEnabled)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        { isAdvertisementEnabled && <ImagesContainer 
            images={previewImages} 
            addImages={addImages} 
            deleteImage={deleteImage} 
            classes={classes}
          />
        }
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={saveVillageOptions}
            // endIcon={<AddIcon />}
            >
            {villageOptions?.isDefault ? 'Save current configuration' : 'Update configuration'}
        </Button>
      </CardContent>
    </Card>
  ) : null;
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    minWidth: 275,
    marginTop: '1.5rem',
    background: '#b1b3dd33'
  },
  card: {
    display: 'flex',
    flexDirection: 'column'
  },
  smallBtn: {
    marginBottom: '1rem',
    float: 'right'
  },
  button: {
    position: 'absolute',
    top: '20px',
    right: '10px'
  },
  addImagesButton: {

  },
  optionsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '& div': {
      width: '50%'
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:768px)']: {
      flexDirection: 'column',
      '& div': {
        width: '100%'
      }
    },
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1rem'
  },
  input: {
    margin: '20px 0',
    '& div': {
      width: '80%'
    }
  },
  imagesContainer: {
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    }
  },
  imageCard: {
    marginTop: '10px',
    marginRight: '30px',
    display: 'flex',
    width: '200px',
    height: '200px',
    overflow: 'hidden',
    ' & img': {
      objectFit: 'contain',
      width: '200px',
      height: '100%'
    }
  },
  uploadInput: {
    display: 'none',
    width: '0',
    height: '0'
  }
}));

export default VillageOptions;
