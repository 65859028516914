import axios from '.';

export const getConnectionRequest = () => {
  return axios.get(`/village/connections`);
};

export const getUnconnectedVillagesRequest = () => {
  return axios.get('/village/unconnected');
}

export const createConnectionRequest = (data) => {
  return axios.post('/village/connection', data);
};

export const updateConnectionAccessRequest = (data) => {
  return axios.patch('/village/connection-access', data);
};

export const addVillageToConnectionRequest = (data) => {
  return axios.patch('/village/add-connection', data);
};
