import React, { useState } from 'react';
import { makeStyles, Menu } from '@material-ui/core';
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, } from '@material-ui/core';

const SelectVillages = ({ icon, color, className, variant, title, villages, setVillages, checkedVillages }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  const handleToggle = (village) => () => {
    const currentVillage = checkedVillages.some(v => v.id === village.id);
    let newChecked = [];

    if (currentVillage) {
      newChecked = checkedVillages.filter(v => v.id !== village.id);
    } else {
      newChecked = [...checkedVillages, village];
    }
    setVillages(newChecked);
  };

  return (
    <div className={classes.container}>
    <Button
      className={className || classes.button}
      color={color || 'default'}
      variant={variant || 'outlined'}
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={handleClick}
      endIcon={icon || null}
    >
      {title}
    </Button>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <List dense className={classes.root}>
      {villages?.map((village) => {
        const labelId = `checkbox-list-secondary-label-${village.name}`;
        return (
          <ListItem key={village.name} button>
            <ListItemText id={labelId} primary={village.name} />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(village)}
                checked={checkedVillages.some(c => c.id === village.id)}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    </Menu>
  </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 300,
    maxHeight: 500,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    marginTop: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end'
  },
  button: {
  }
}));

export default SelectVillages;
