import React, { useEffect } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getPwaStatistic } from 'store/user';


const PwaStatistic = () => {
  const dispatch = useDispatch();
  const { pwaStatistic } = useSelector(state => ({
    pwaStatistic: state.users.pwaStatistic
  }));

  useEffect(() => {
    dispatch(getPwaStatistic());
  }, [dispatch]);

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <h3 className={classes.tableHeader}>PWA statistic</h3>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.textBold} size="small" />
            <TableCell className={classes.textBold} align="left">iPhone PWA</TableCell>
            <TableCell className={classes.textBold} align="left">iPhone Browser</TableCell>
            <TableCell className={classes.textBold} align="left">Android PWA</TableCell>
            <TableCell className={classes.textBold} align="left">Android Browser</TableCell>
            <TableCell className={classes.textBold} align="left">Browser</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.textBold} component="th" scope="village" size="small">
              Total
            </TableCell>
            <TableCell align="left">{pwaStatistic?.iphonePwa}</TableCell>
            <TableCell align="left">{pwaStatistic?.iphoneBrowser}</TableCell>
            <TableCell align="left">{pwaStatistic?.androidPwa}</TableCell>
            <TableCell align="left">{pwaStatistic?.androidBrowser}</TableCell>
            <TableCell align="left">{pwaStatistic?.desktop}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles({
  textBold: {
    fontWeight: 'bold'
  },
  tableHeader: {
    background: '#284eff1c',
    marginTop: 0,
    padding: '.5rem'
  },
  table: {
    minWidth: 650,
  },
});

export default PwaStatistic;
