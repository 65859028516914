import React, { useEffect, useState } from 'react';
import { IconButton, makeStyles, Paper } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { downloadDbBackup, getDbBackup } from 'api/village';
import { format, parseISO } from 'date-fns'
// import config from 'config';

const Application = () => {
  const classes = useStyles();
  const [backup, setBackup] = useState({});

  const getBackup = async () => {
    try {
      const data = await getDbBackup();
      if (data.backup) {
        setBackup(data.backup)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBackup();
  }, []);

  const downloadBackup = async () => {
    const file = await downloadDbBackup(backup.hash);
    console.log(file);
  };

  const backupDate = backup.updatedAt ? format(parseISO(backup.updatedAt), "MMM d 'At' h:m a") : '';
  // const backupLink = `${config.serverUrl}/village/backup/${backup.hash}`
  return (
    <Paper elevation={3} className={classes.root}>
      <h3 className={classes.pageHeader}>Database backup</h3>
      {backup.updatedAt && (
      <div className={classes.downloadSection}>
        <h4>
        {backupDate}
        </h4>
        <h4>
          every day at 05:00
        </h4>
        {/* <a href={backupLink} download> */}
        <IconButton disabled={true} color="primary" onClick={downloadBackup}>
          <DownloadIcon />
        </IconButton>
        {/* </a> */}
      </div>
      )}
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  root: {
      paddingBottom: '10px'
  },
  pageHeader: {
    background: '#284eff1c',
    marginTop: 0,
    padding: '.5rem'
  },
  downloadSection: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
}));

export default Application;