import React from 'react';
import styled from 'styled-components';
import PwaStatistic from './PwaStatistic';
import UserActivity from './UserActivity';

const UserPage = () => {
  return (
    <StyledUserPage>
      <h1>
        Users
      </h1>
      <PwaStatistic />
      <UserActivity />
    </StyledUserPage>
  );
};

const StyledUserPage = styled.div`

`;

export default UserPage;
