import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserRequest, getPwaStatisticRequest, getUserActivityRequest } from '../api/user';

const initialState = {
  user: {
    id: null,
    firstName: 'John',
    lastName: 'Doe',
    loading: false
  },
  pwaStatistic: null,
  userActivity: null
};

export const getUser = createAsyncThunk(
  'users/getOne',
  async (id) => {
    const res = await getUserRequest(id);
    return res;
  }
);

export const getPwaStatistic = createAsyncThunk(
  'users/pwa-statistic',
  async () => {
    const res = await getPwaStatisticRequest();
    return res;
  }
);

export const getUserActivity = createAsyncThunk(
  'users/activity',
  async (params) => {
    const res = await getUserActivityRequest(params);
    return res;
  }
);

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: { },
  extraReducers: builder => {
    builder
      .addCase(getUser.pending, state => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.user = { ...payload, loading: false }
      })
      .addCase(getPwaStatistic.fulfilled, (state, { payload }) => {
        state.pwaStatistic = payload.pwaStatistic;
      })
      .addCase(getUserActivity.fulfilled, (state, { payload }) => {
        state.userActivity = payload.userActivity;
      })
  }
});

export default userSlice.reducer;
