import React from 'react';
import Header from '../componets/Header';
import Sidebar from '../componets/Sidebar';

const Navbar = () => {

  return (
    <>
      <Header />
      <Sidebar />
    </>
  )
};

export default Navbar;
