import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getConnections, selectConnections } from 'store/connection';
import Connection from './Connection';

const ConnectionsList = () => {
  const connections = useSelector(selectConnections);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConnections());
  }, [dispatch]);
  
  const classes = useStyles();
  return (
    <>
    <div className={classes.header}>
        <h3>Villages Connections</h3>
        <Link to="/connections/create">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<AddIcon />}
            >
            Create new connection
          </Button>
        </Link>
      </div>
      <div>
        {connections?.map((connection, idx) => (
          <Connection key={idx} connection={connection} />
        ))}
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    display: 'block',
    minHeight: '7.5rem'
  },
  button: {
    float: 'right'
  }
}));

export default ConnectionsList;

