import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginRequest, authRequest } from 'api/auth';
import { storage } from 'utils';

const initialState = {
  user: null,
  status: 'success'
};

export const login = createAsyncThunk(
  'auth/login',
  async (data) => {
    const res = await loginRequest(data);
    if (res.accessToken) {
      storage.setAccessToken(res.accessToken);
    }
    return res;
  }
);

export const authorize = createAsyncThunk(
  'auth/authorize',
  async () => {
    const res = await authRequest();
    return res;
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: { },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.status = 'success';
      })
      .addCase(login.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(authorize.pending, state => {
        state.status = 'loading';
      })
      .addCase(authorize.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.status = 'success';
      })
      .addCase(authorize.rejected, (state) => {
        state.status = 'failed';
      })
  }
});
export const selectStatus = (state) => state.profile.status;
export const selectProfile = (state) => state.profile.user

export default profileSlice.reducer;

