import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOneVillageConfigurationsRequest,
  createOneVillageConfigurationsRequest,
  updateOneVillageConfigurationsRequest,
  deleteOneVillageConfigurationsRequest
} from '../api/villageConfigs';

import { addPrefix } from '../utils/prefixes';


const initialState = {
  defaultCongigurations: {
    isDefault: true,
    isLiveOptionEnabled: true,
    isAdvertisementEnabled: false,
    advertisementImages: [],
    customOptions: [
      {
        name: 'burgerportal',
        type: 'input',
        title: "Bürgerportal",
        url:  ""
      },
      {
        name: 'citizenService',
        type: 'input',
        title: "Internetseite",
        url:  ""
      },
      {
        name: 'forms',
        type: 'input',
        title: "Forms",
        url:  ""
      },
      {
        name: 'reports',
        type: 'checkbox',
        title: 'Schadensmelder',
        value: true
      }
    ]
  },
};

export const getVillageConfig = createAsyncThunk(
  'villageConfigs/getVillageConfig',
  async (id) => {
    const res = await getOneVillageConfigurationsRequest(id);
    return res;
  }
);

// export const getAllConfigs = createAsyncThunk(
//   'villageConfigs/getAllConfigs',
//   async () => {
//     const res = await getOneVillageConfigurationsRequest();
//     return res;
//   }
// );

export const createCustomConfig = createAsyncThunk(
  'villageConfigs/createCustomConfig',
  async (data) => {
    const res = await createOneVillageConfigurationsRequest(data);
    return res;
  }
);

export const updateConfigurations = createAsyncThunk(
  'villageConfigs/updateConfigurations',
  async (data) => {
    const res = await updateOneVillageConfigurationsRequest(data);
    return res;
  }
);

export const clearConfigurations = createAsyncThunk(
  'villageConfigs/clearConfigurations',
  async (data) => {
    const res = await deleteOneVillageConfigurationsRequest(data);
    return res;
  }
);

export const villageConfigurationsSlice = createSlice({
  name: 'villageConfigurations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVillageConfig.pending, state => {
        state.loading = true;
      })
      .addCase(getVillageConfig.fulfilled, (state, action) => {
        if (action.payload?.villageConfiguration) {
          const advertisementImages = [];
          if (action.payload.villageConfiguration?.advertisementImages.length > 0) {
            action.payload.villageConfiguration.advertisementImages.forEach(el => advertisementImages.push(addPrefix(el)))
          }
          state.currentConfiguration = { ...action.payload.villageConfiguration, advertisementImages: advertisementImages};
        } else {
          state.currentConfiguration = initialState.defaultCongigurations;
        }
        state.loading = false
      })
      .addCase(createCustomConfig.pending, state => {
        state.loading = true;
      })
      .addCase(createCustomConfig.fulfilled, (state, action) => {
        state.villageConfigurations = {
          ...action.payload.villageConfiguration
        }
        state.loading = false
      })
      .addCase(updateConfigurations.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateConfigurations.fulfilled, (state, action) => {
        state.villageConfigurations = action.payload.villageConfiguration;
        state.loading = false;
        state.error = false;
      })
      .addCase(updateConfigurations.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(clearConfigurations.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(clearConfigurations.fulfilled, (state, action) => {
        state.villageConfigurations = action.payload.villageConfiguration;
        state.loading = false;
        state.error = false;
      })
      .addCase(clearConfigurations.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
  }
});

export const selectConnections = state => state.villages.configurations;
export const selectLoading = state => state.villages.loading;
export const selectError = state => state.villages.error;

export default villageConfigurationsSlice.reducer;
