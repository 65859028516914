const env = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';

const config = {
  development: {
    serverUrl: 'http://localhost:6800/api'
  },
  stage: {
    serverUrl: 'http://18.194.32.27/api'
  },
  production: {
    serverUrl: 'https://admin.villageapp.de/api'
  }
};

export default config[env];
