import VillagesList from "../pages/village/VillagesList";
import UserPage from "../pages/user";
import CreateConnection from "pages/connection/CreateConnection";
import CreateVillage from "pages/village/CreateVillage";
import VillagesOptions from "pages/villagesOptions";
import ConnectionsList from "pages/connection/ConnectionsList";
import Application from "pages/application/Application";
import VillageMetrics from "pages/village/VillageMetrics";

const routes = [
  {
    path: '/',
    exact: true,
    Component: VillagesList,
  },
  {
    path: '/villages',
    exact: true,
    Component: VillagesList,
  },
  {
    path: '/villages/create',
    exact: true,
    Component: CreateVillage
  },
  {
    path: '/villages_options',
    exact: true,
    Component: VillagesOptions
  },
  {
    path: '/villages/:id',
    exact: true,
    Component: VillageMetrics,
  },
  {
    path: '/users',
    exact: true,
    Component: UserPage,
  },
  {
    path: '/connections',
    exact: true,
    Component: ConnectionsList
  },
  {
    path: '/connections/create',
    exact: true,
    Component: CreateConnection
  },
  {
    path: '/application',
    exact: true,
    Component: Application
  },
  {
    path: '/',
    exact: false,
    Component: VillagesList,
  }
];
export default routes;