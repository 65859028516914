import axios from '.';

export const getUserRequest = (id) => {
  return axios.get(`/users/${id}`);
};

export const getPwaStatisticRequest = () => {
  return axios.get('/village/pwa-statistic');
};

export const getUserActivityRequest = (params) => axios.get('/village/users-activity', { params });
