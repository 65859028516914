import React, { useEffect } from 'react';
import { BrowserRouter} from 'react-router-dom';
import Main from 'containers/Main';
import Navbar from './containers/Navbar';
import { useDispatch, useSelector } from "react-redux";
import { authorize, selectProfile, selectStatus } from "store/profile";
import { toggleHeader } from "store/app";

const App = () => {
  const user = useSelector(selectProfile);
  const status  = useSelector(selectStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(toggleHeader(true));
    } else {
    dispatch(authorize());
    }
  }, [dispatch, user]);
  if (status !== 'failed' && !user) {
    return (
      <h1>Loading...</h1>
    );
  }
  return (
    <BrowserRouter>
      <Navbar />
      <Main />
    </BrowserRouter>
  );
};

export default App;
