import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getConnectionRequest,
  getUnconnectedVillagesRequest,
  createConnectionRequest,
  updateConnectionAccessRequest,
  addVillageToConnectionRequest
} from '../api/connection';

const initialState = {
  connections: null,
  unconnectedVillages: null, 
  loading: false,
  error: false
};

export const getConnections = createAsyncThunk(
  'connection/getConnections',
  async () => {
    const res = await getConnectionRequest();
    return res;
  }
);

export const getUnconnectedVillages = createAsyncThunk(
  'connection/getUnconnectedVillages',
  async () => {
    const res = await getUnconnectedVillagesRequest();
    return res;
  }
);

export const createConnection = createAsyncThunk(
  'connection/createConnection',
  async (data) => {
    const res = await createConnectionRequest(data);
    return res;
  }
);

export const addVillageToConnection = createAsyncThunk(
  'connection/addVillage',
  async (data) => {
    const res = await addVillageToConnectionRequest(data);
    return res;
  }
);

export const updateConnectionAccess = createAsyncThunk(
  'connection/updateConnectionAccess',
  async (data) => {
    const res = await updateConnectionAccessRequest(data);
    return res;
  }
);

export const connectionSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getConnections.pending, state => {
        state.loading = true;
      })
      .addCase(getConnections.fulfilled, (state, action) => {
        state.connections = action.payload.connections;
        state.loading = false
      })
      .addCase(getUnconnectedVillages.pending, state => {
        state.loading = true;
      })
      .addCase(getUnconnectedVillages.fulfilled, (state, action) => {
        state.unconnectedVillages = action.payload.villages;
        state.loading = false
      })
      .addCase(createConnection.pending, state => {
        state.loading = true;
      })
      .addCase(createConnection.fulfilled, (state, action) => {
        state.connections = [...state.connections, action.payload.connection];
        state.loading = false
      })
      .addCase(updateConnectionAccess.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateConnectionAccess.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(updateConnectionAccess.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(addVillageToConnection.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addVillageToConnection.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(addVillageToConnection.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      
  }
});

export const selectConnections = state => state.connections.connections;
export const selectLoading = state => state.connections.loading;
export const selectError = state => state.connections.error;
export const selectUnconnectedVillages = state => state.connections.unconnectedVillages;

export default connectionSlice.reducer;
