import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getVillagesRequest,
  createVillagesRequest,
  getVillageMetricsRequest
} from '../api/village';

const initialState = {
  villages: null,
  connections: null,
  unconnectedVillages: null, 
  loading: false,
  villageMetrics: null
};

export const getvillages = createAsyncThunk(
  'villages/getVillages',
  async () => {
    const res = await getVillagesRequest();
    return res;
  }
);

export const createVillage = createAsyncThunk(
  'villages/createVillage',
  async (data) => {
    await createVillagesRequest(data);
  }
);

export const getvillageMetrics = createAsyncThunk(
  'villages/getVillageMetrics',
  async (id) => {
    const res = await getVillageMetricsRequest(id);
    return res;
  }
);

export const villageSlice = createSlice({
  name: 'villages',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getvillages.pending, state => {
        state.loading = true;
      })
      .addCase(getvillages.fulfilled, (state, action) => {
        state.villages = action.payload.villages;
        state.loading = false;
      })
      .addCase(createVillage.pending, state => {
        state.loading = true;
      })
      .addCase(createVillage.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getvillageMetrics.fulfilled, (state, action) => {
        state.villageMetrics = action.payload.villageMetrics;
      });
  }
});

export const selectConnections = state => state.villages.connections;

export default villageSlice.reducer;
