import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
} from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../store/app';
import { Link } from 'react-router-dom';
import { getvillages } from 'store/village';

const Sidebar = () => {
  const { isSidebarOpen } = useSelector((state) => ({
    isSidebarOpen: state.app.isSidebarOpen,
    villages: state.villages.villages
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getvillages());
  }, [dispatch]);
  const closeSidebar = () => {
    dispatch(toggleSidebar());
  };

  const classes = useStyles();
  const routes = ['users', 'villages', 'connections', 'villages_options'];
  return (
    <Drawer
      className={classes.drawer}
      onClose={closeSidebar}
      anchor="left"
      open={isSidebarOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <h3 className={classes.logo}>villagesAdmin</h3>
        <IconButton onClick={closeSidebar}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {routes.map((route) => (
          <Link key={route} to={`/${route}`} onClick={closeSidebar}>
            <ListItem button>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={route} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <Link to="/application" onClick={closeSidebar}>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="application" />
        </ListItem>
      </Link>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: theme.sidebar.width,
    flexShrink: 0,
  },
  drawerPaper: {
    borderRight: 'none',
    ...theme.sidebar,
  },
  drawerHeader: {
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  logo: {
    marginLeft: '1rem'
  }
}));

export default Sidebar;
