import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSidebarOpen: false,
  isHeaderVisible: false
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.isSidebarOpen = action.payload
    },
    toggleHeader: (state, action) => {
      state.isHeaderVisible = action.payload
    }
  }
});

export const { toggleSidebar, toggleHeader } = appSlice.actions;

export default appSlice.reducer;