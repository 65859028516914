import axios from '.';

import { getFormDataFromObject } from 'utils/formData';

export const getOneVillageConfigurationsRequest = (id) => {
  return axios.get(`/village-configurations/village/${id}`);
};

export const createOneVillageConfigurationsRequest = (data) => {
  const formData = getFormDataFromObject(data);
  
  return axios.post(`/village-configurations`, formData)
};

export const updateOneVillageConfigurationsRequest = (data) => {
  const formData = getFormDataFromObject(data);
  return axios.patch(`/village-configurations/`, formData);
};

export const deleteOneVillageConfigurationsRequest = (id) => {
  return axios.delete(`/village-configurations/${id}`);
};
